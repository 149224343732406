import React from "react";
import { object } from "prop-types";

import {
  Layout,
  SEO,
  BannerStandard,
  ContainerContentFixed,
  ContainerBackgroundWhite,
} from "../components";

const Terms = ({ style }) => (
  <Layout style={style}>
    <SEO title={"Terms of use"} />
    <BannerStandard
      title={"Terms of Use"}
      subTitle={"Applicable to all services"}
    />

    <ContainerBackgroundWhite>
      <ContainerContentFixed style={{ maxWidth: "760px" }}>
        <h4>Please read these terms and conditions carefully.</h4>
        <ol>
          <li>
            These terms and conditions apply to the use of the websites
            pdview.com.au, daeg.com.au, haeg.com.au, waeg.com.au, fcaspays.com.au and their associated APIs.
            By using these websites, you agree to these terms and conditions. If
            you do not accept these terms and conditions, you must not use these
            websites.
          </li>
          <li>
            These website are owned and operated by pdView Pty Ltd (ACN: 602 893
            872, ABN:25 602 893 872) from Melbourne, Victoria, Australia. All
            uses of the words "I", "we", "us" etc. refer to this company. Any
            reference to "website", "site", "API" etc. in the singular or plural
            should be considered to be referring to all the sites daeg.com.au
            and their associated Application Programming Interfaces (APIs).
          </li>
          <li>
            Subscription:
            <ol>
              <li>
                This website is only available to paid subscribers. If you are
                not a paid subscriber then you must not use this website.
              </li>
              <li>
                Paid subscribers must be Australian registered companies only.
                This website is not available to individuals and hence is not
                subject to the Australian Consumer Law.
              </li>
              <li>
                Employees of paid subscribers may only use this website if
                authorised by their employer.
              </li>
              <li>
                If you cease to be employed by your employer than you must cease
                your use of this site.
              </li>
            </ol>
          </li>
          <li>
            You must not use this website or its contents:
            <ol>
              <li>in breach any law or regulation,</li>
              <li>to infringe a third party's rights,</li>
              <li>
                to breach any standards, content requirements or codes promoted
                by any relevant authority, or
              </li>
              <li>
                in connection with any information or material that may be, or
                that may encourage conduct that may be unlawful, threatening,
                abusive, defamatory, obscene, pornographic, profane or indecent.
              </li>
            </ol>
          </li>
          <li>
            Access to certain parts of these sites may be protected by security
            credentials (such as username, passwords, API keys etc.). You agree
            that:
            <ol>
              <li>
                You will keep these credentials private and not share them with
                anyone.
              </li>
              <li>
                You will inform pdView if your security credentials have been
                obtained by a third party.
              </li>
              <li>
                You acknowledge that pdView may revoke your security credentials
                if we suspect that your security credentials have been stolen or
                shared with a third party.
              </li>
            </ol>
          </li>
          <li>
            Intellectual property:
            <ol>
              <li>
                All intellectual property rights, including copyright, in this
                website and its contents are owned or licensed by us or are used
                by permission. Except as expressly permitted by the Copyright
                Act 1968 (Cth), and these terms and conditions, you must not
                reproduce, modify or adapt this website or its contents.
              </li>
              <li>
                You may download data from this site using the API and use it
                inside your organisation but you may not distribute it to
                persons outside of your organisation.
              </li>
              <li>
                You acknowledge that these terms and conditions do not transfer
                any intellectual property right to you in this website or its
                contents.
              </li>
            </ol>
          </li>
          <li>
            Limitation of liability:
            <ol>
              <li>
                To the extent that any implied condition, warranty, term or
                remedy cannot lawfully be excluded from these terms and
                conditions ('Non-excludable Condition'), it is included in them.
              </li>
              <li>
                Subject to that, we:
                <ul>
                  <li>
                    are not liable for any direct or indirect loss or damage,
                    however caused (including by negligence), arising out of
                    your use of or unavailability of these websites, its
                    contents, or any linked websites,
                  </li>
                </ul>
              </li>
              <li>
                You acknowledge that the data provided in this website is
                calculated using a mathmatical algorithm that may be numerically
                unstable and may contain errors in the input data, logic,
                programming and its presentation to you. pdView is not liable
                for your use of or reliance on this content and it is provided
                'as is'.
              </li>
              <li>
                You also acknowledge that data provided by this website may be
                delayed or not created at all due to delays in obtaining
                required input data from third parties, server error or caching
                by third parties.
              </li>
            </ol>
          </li>
          <li>
            These Terms and Conditions are governed by and construed in
            accordance with the State of Victoria, Australia. In the event that
            a dispute arises from these Website terms and conditions, you agree
            to submit to the non-exclusive jurisdiction of the courts of
            Victoria, Australia.
          </li>
        </ol>
      </ContainerContentFixed>
    </ContainerBackgroundWhite>
  </Layout>
);

Terms.propTypes = {
  style: object,
};

export default Terms;
